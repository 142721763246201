import { mapValues } from 'lodash';
import moment from 'moment';

/**
 * @param ref
 * @param {Function} ctor
 */
export function populatedCtor(ref, ctor) {
  return ref instanceof ctor ? ref : ref && typeof ref === 'object' ? new ctor(ref) : ref;
}

/**
 * @param refOrArrayOrMap
 * @param {Function} ctor
 */
export function crudReadCtor(refOrArrayOrMap, ctor) {
  if (refOrArrayOrMap instanceof ctor) {
    return refOrArrayOrMap;
  } else {
    if (refOrArrayOrMap && typeof refOrArrayOrMap === 'object') {
      if (Array.isArray(refOrArrayOrMap)) {
        return populatedArrayCtor(refOrArrayOrMap, ctor);
      } else if ('_id' in refOrArrayOrMap) {
        return new ctor(refOrArrayOrMap);
      } else {
        // treat as map
        return mapValues(refOrArrayOrMap, x => populatedCtor(x, ctor));
      }
    } else {
      return refOrArrayOrMap;
    }
  }
}

/**
 * @param {*[]} refs
 * @param {Function} ctor
 */
export function populatedArrayCtor(refs, ctor) {
  return Array.isArray(refs) ? refs.map(x => populatedCtor(x, ctor)) : refs;
}

export function parseDate(v) {
  return v ? moment(v).toDate() : v;
}

export class GlobalSchemaFields {
  /** @type {string} */
  _id;
  /** @type {Date} */
  created_at;
  /** @type {Date} */
  updated_at;

  constructor(doc) {
    if (doc) {
      doc.created_at = parseDate(doc.created_at);
      doc.updated_at = parseDate(doc.updated_at);
      Object.assign(this, doc);
    }
  }
}
