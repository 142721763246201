<template>
  <v-app>
    <v-alert class="popAlert" v-if="displayAlert" :type="alertType" :color="color">
      <div style="text-align: center">{{ alertMessage }}</div>
      <template v-slot:close>
        <v-icon @click="closeAlert"> mdi-close</v-icon>
      </template>
    </v-alert>
    <v-navigation-drawer permanent expand-on-hover v-model="drawer" app>
      <drawer />
    </v-navigation-drawer>
    <v-app-bar app flat dark color="primary">
      <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />-->
      <!--      </img src="@/assets/logo.svg" style="width: 30px; height: 30px; padding: 2.5px" />-->
      <v-toolbar-title>IM Biz</v-toolbar-title>
      <!--      <v-menu bottom right>-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <v-btn icon color="inherit" v-bind="attrs" v-on="on">-->
      <!--            <v-icon>mdi-dots-vertical</v-icon>-->
      <!--          </v-btn>-->
      <!--        </template>-->

      <!--        <v-list>-->
      <!--          <v-list-item v-for="(item, i) in items" :key="i">-->
      <!--            <v-list-item-title>{{ item.title }}</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--        </v-list>-->
      <!--      </v-menu>-->
      <v-spacer />
      <div>
        <a class="fletrix-link" href="https://fletrix.com.hk/"> Powered by Fletrix </a>
        <small>{{ buildVersion }}</small>
      </div>
      <v-spacer />
      <div style="padding-right: 10px">
        <h3>
          {{ profile && profile.name }}
          ({{ role | capitalize }})
        </h3>
      </div>
      <v-btn icon @click="myProfile" class="mr-8">
        <v-icon style="font-size: 30px"> mdi-account</v-icon>
      </v-btn>
      <v-btn icon @click="actionLogout" v-if="isLoggedIn">
        <v-icon style="font-size: 30px">mdi-logout-variant</v-icon>
      </v-btn>
      <v-btn icon v-else>
        <v-icon style="font-size: 30px">mdi-login-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <BlockedView v-if="loginBlocked" class="text-center">
        <div style="background-color: red; padding: 50px; border-radius: 100px">
          <h2 style="color: white; text-align: center; margin-bottom: 20px">
            <template>
              <h3 v-if="loginBlocked.subcode === 'needApproval'">
                Your login is pending for Team Manager approval
              </h3>
              <h3 v-else-if="loginBlocked.subcode === 'workTimePeriod'">
                Your work time period expired
              </h3>
              <h3 v-else>You have been blocked, please contact your manager</h3>
            </template>
          </h2>
          <v-btn @click="actionLogout"> Got it</v-btn>
        </div>
      </BlockedView>
      <router-view v-else />
    </v-main>
    <div class="panel">
      <PendingLoginManagePanel v-if="isPendingRequest" />
      <idleChatsAlert v-if="haveIdleChat" v-bind:docs="docs" />
    </div>
  </v-app>
</template>

<script>
import api from '@/api';
import { listIdleChats } from '@/api/team';
import BlockedView from '@/components/BlockedView';
import idleChatsAlert from '@/components/idleChatsAlert';
import PendingLoginManagePanel from '@/components/pendingLoginManagePanel';
import Drawer from '@/layout/partial/drawerNav';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { listNotification } from '@/api/notification';

export default {
  components: { BlockedView, Drawer, PendingLoginManagePanel, idleChatsAlert },
  data() {
    return {
      drawer: false,
      docs: null,
      checkIdleChatsTimer: null,
      checkNotificationTimer: null,
    };
  },

  computed: {
    ...mapGetters('alert', ['alertType', 'displayAlert', 'alertMessage', 'color']),
    ...mapGetters('auth', [
      'profile',
      'role',
      'isLoggedIn',
      'loginBlocked',
      'isManager',
      'profileId',
    ]),
    ...mapGetters('pendingLoginManagePanel', ['isPendingRequest']),
    ...mapGetters('notification', ['needNotify']),

    buildVersion() {
      const { time, git } = process.env.BUILD;
      return `ver: ${git.version}-${git.branch} (${moment(time).format('YYYY-MM-DD HH:mm')})`;
    },
    haveIdleChat() {
      return this.docs?.length;
    },
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler(x) {
        if (!x) {
          this.$router.replace({ name: 'login' }).catch(() => {});
        }
      },
    },
    isManager: {
      immediate: true,
      handler(x) {
        if (x) {
          this.checkIdleChatsTimer = setInterval(this.checkIdleChats, 30000);
        } else {
          clearInterval(this.checkIdleChatsTimer);
        }
      },
    },
  },
  mounted() {
    this.checkNotificationTimer = setInterval(this.notification, 30000);
    this.notification();
  },
  beforeDestroy() {
    clearInterval(this.checkNotificationTimer);
  },
  methods: {
    ...mapActions('alert', ['closeAlert']),
    ...mapActions('auth', ['logout']),
    myProfile() {
      this.$router.push({ name: 'myprofile' }).catch(() => {});
    },
    async notification() {
      const temp = await listNotification(api, {
        filter: { read: { $eq: null } },
        limit: 0,
      });
      if (temp.total > 0) {
        await this.$store.dispatch('notification/notify', true);
      }
    },
    async actionLogout() {
      try {
        await this.logout();
      } catch (e) {}
      this.$router.replace({ name: 'login' }).catch(() => {});
    },
    async checkIdleChats() {
      if (this.isManager) {
        this.docs = await listIdleChats(api);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog--active {
  border-radius: 20px;
}

.v-main {
  height: 100vh;
}

.popAlert {
  position: fixed;
  bottom: 0;
  z-index: 900;
  width: 60%;
  left: calc((100% - 60%) / 2);
}

.panel {
  left: 20%;
  width: 60%;
  position: fixed;
  top: 64px;
}

.fletrix-link {
  color: white !important;
  text-decoration: none;
  margin-right: 12px;
}
</style>
