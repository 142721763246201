<template>
  <div style="margin-bottom: 8px">
    <div class="mainTitle">
      <span>{{ title }}</span>
    </div>
    <div class="itemFieldName">
      <span>{{ itemFieldName }}</span>
    </div>
    <div class="text">
      <span>{{ text }}</span>
    </div>

    <div>
      <slot name="addMore"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customLabel',
  props: {
    title: String,
    itemFieldName: String,
    text: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.mainTitle {
  color: #32325d;
  font-size: 17px;
  font-weight: 600;
}

.text {
  color: #8898aa;
  font-size: 14px;
}

.itemFieldName {
  font-weight: 600;
  color: #525f7f;
  font-size: 14px;
}
</style>
