<template>
  <v-container style="height: 100%">
    <LoginForm :ret="ret" />
  </v-container>
</template>

<script>
import LoginForm from '@/components/LoginForm/LoginForm';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginPage',
  components: { LoginForm },
  props: {
    ret: {},
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.replace('/').catch(() => {});
    }
  },
};
</script>
