import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export const defaults = Object.freeze({
  primary: '#6C7FEE',
  primary_inverse: '#FFFFFF',
  secondary: '#6E7FF1',
  success: '#8DDC88',
  info: '#71BAFF',
  danger: '#F8706D',
  warning: '#ffcc66',
  warning_inverse: '#000',
  accent: '#82B1FF',
  error: '#FF5252',
  itemLabel: '#525f7f',
  mainTitleLabel: '#32325d',
  whatsappGrey: '#EDEDED',
  messageBackground: '#f2f2f2',
  managerColor: '#2196F3',
  managerColor_inverse: '#FFFFFF',
});

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: { ...defaults },
    },
  },
});
