import ApiError from '@/api/ApiError';

const state = {
  alertType: 'error',
  color: 'danger',
  alertMessage: null,
  timeout: null,
  timer: null,
};
const actions = {
  updateAlertMessage({ commit, dispatch }, { msg, type, timeout, color } = {}) {
    if (msg instanceof ApiError) {
      // ignore
    } else if (msg instanceof Error) {
      // log javascript errors
      console.error(msg);
    }
    if (timeout !== false) timeout = timeout || 3000;
    commit('setAlertMessage', msg);
    commit('setAlertType', type || 'error');
    commit('setColor', color || 'danger');
    commit('setTimeout', timeout);
    dispatch('_setTimer');
  },
  closeAlert({ commit, dispatch }) {
    commit('setAlertMessage', null);
    commit('setAlertType', null);
    commit('setColor', null);
    commit('setTimeout', null);
    dispatch('_setTimer');
  },
  _setTimer({ commit, dispatch, state }) {
    clearTimeout(state.timer);
    if (Number.isFinite(state.timeout)) {
      commit(
        '_setTimer',
        setTimeout(() => dispatch('closeAlert'), state.timeout),
      );
    }
  },
};

const mutations = {
  setAlertMessage(state, alertMessage) {
    state.alertMessage = alertMessage;
  },
  setAlertType(state, type) {
    state.alertType = type;
  },
  setTimeout(state, timeout) {
    state.timeout = timeout;
  },
  setColor(state, color) {
    state.color = color;
  },
  _setTimer(state, timer) {
    state.timer = timer;
  },
};

const getters = {
  alertMessage: state => state.alertMessage,
  displayAlert: state => !!state.alertMessage,
  color: state => state.color,
  alertType: state => state.alertType,
  timeout: state => state.timeout,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
