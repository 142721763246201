import { IProfile } from '@/api/auth/membership';
import { GlobalSchemaFields, populatedArrayCtor, populatedCtor } from '@/api/mongooseTypes';
import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @return {Promise<IToken[]>}
 */
export async function listLoginRequests(api) {
  const { data } = await api.get('im/loginRequests');
  return populatedArrayCtor(data, IToken);
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {'approve'|'reject'} action
 * @return {Promise<void>}
 */
export async function approveOrRejectLoginRequest(api, id, action) {
  await api.patch(`im/loginRequests/${id}/${action}`);
}

export class IToken extends GlobalSchemaFields {
  /** @type {IProfile} */
  profile;
  /**
   * @type {{needApproval?: boolean, team?: string, loginApproved?: boolean}}
   */
  data;

  constructor(obj) {
    super(obj);
    obj.profile = populatedCtor(obj.profile, IProfile);
    Object.assign(this, obj);
  }
}
