import { AxiosInstance } from 'axios';
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';

/**
 * @param {AxiosInstance} api
 * @param {string} nsp
 * @param { Partial<ManagerOptions & SocketOptions>} [opts]
 * @return {Socket}
 */
export function createSocket(api, nsp, opts) {
  return io(api.defaults.host + nsp, {
    path: api.defaults.base + '/im/socket',
    withCredentials: true,
    ...opts,
  });
}
