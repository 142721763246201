import api from '@/api';
import { listLoginRequests } from '@/api/loginRequest';

const state = {
  /** @type {IToken[]} */
  pendingRequests: [
    // {
    //   profile: {
    //     role: 'aaabbbddd',
    //     name: 'Sam Wong',
    //     phone: '123',
    //   },
    // },
  ],
};
const actions = {
  async fetchPendingTokens({ commit }) {
    commit('setPendingRequests', await listLoginRequests(api));
  },
};

const mutations = {
  setPendingRequests(state, x) {
    state.pendingRequests = x || [];
  },
};

const getters = {
  isPendingRequest: (state, _, __, getters) => {
    return getters['auth/isManager'] && !!state.pendingRequests.length;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
