<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header class="panelHeader">
        <span class="textColor">
          {{ pendingRequests[0].profile.name }} ({{ pendingRequests[0].profile.role }}) requests to
          login to the system, Approve?
        </span>
        <div style="text-align: end; white-space: nowrap">
          <v-btn
            class="approveBtn"
            color="success"
            fab
            @click.stop="approve(pendingRequests[0]._id)"
          >
            <v-icon> mdi-check </v-icon>
          </v-btn>
          <v-btn class="cancelBtn" color="primary" fab @click.stop="reject(pendingRequests[0]._id)">
            <v-icon> mdi-window-close </v-icon>
          </v-btn>
        </div>
        <template v-slot:actions>
          <v-icon v-if="needExpand"> $expand </v-icon>
          <v-icon v-else style="visibility: hidden" disabled>$expand</v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="panelHeader" v-if="pendingRequests.length > 1">
        <div class="panelContent" v-for="x in pendingRequests.slice(1)" :key="x._id">
          <span class="textColor">
            {{ x.profile.name }} ({{ x.profile.role }}) requests to login to the system, Approve?
          </span>
          <div style="text-align: end; white-space: nowrap">
            <v-btn class="approveBtn" color="success" fab @click.stop="approve(x._id)">
              <v-icon> mdi-check </v-icon>
            </v-btn>
            <v-btn class="cancelBtn" color="primary" fab @click.stop="reject(x._id)">
              <v-icon> mdi-window-close </v-icon>
            </v-btn>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { approveOrRejectLoginRequest } from '@/api/loginRequest';
import api from '@/api';

export default {
  name: 'PendingLoginManagerPanel',
  computed: {
    ...mapState('pendingLoginManagePanel', ['pendingRequests']),
    needExpand() {
      return this.pendingRequests.length !== 1;
    },
  },
  methods: {
    ...mapActions('pendingLoginManagePanel', ['fetchPendingTokens']),
    async approve(id) {
      try {
        await approveOrRejectLoginRequest(api, id, 'approve');
        await this.fetchPendingTokens();
      } catch (err) {
        console.log(err);
        // todo: throw err
      }
    },
    async reject(id) {
      try {
        await approveOrRejectLoginRequest(api, id, 'reject');
        await this.fetchPendingTokens();
      } catch (err) {
        console.log(err);
        // todo: throw err
      }
    },
  },
};
</script>

<style scoped lang="scss">
.panelHeader {
  padding-right: 24px;
  background-color: var(--v-danger-base);
  cursor: initial;
}
.panelContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}
.textColor {
  text-align: left;
  color: white;
  font-size: 20px;
}
.approveBtn {
  margin: 0 10px;
}
.cancelBtn {
  margin: 0 10px;
}
</style>
