import api from '@/api';
import { IChatGateway } from '@/api/gw';
import { populatedCtor } from '@/api/mongooseTypes';
import { createSocket } from '@/api/socket';
import { oidEquals } from '@/services/utils';
import Vue from 'vue';

const state = {
  /**
   * @type {Record<string, IChatGateway>}
   */
  gateways: {},
};
/** @type {Socket} */
let socket = null;

const mutations = {
  setGateway(state, { id, gw }) {
    if (gw) {
      Vue.set(state.gateways, id, gw);
    } else {
      Vue.delete(state.gateways, id);
    }
  },
  reset(state) {
    state.gateways = {};
  },
};

const actions = {
  initSocket({ commit, rootGetters }) {
    socket?.disconnect();
    socket = createSocket(api, '/gw');
    socket.emit('monitor', true);
    socket.on(
      'monitor',
      /**
       *
       * @param {string} id
       * @param {IChatGateway|null} gw
       */
      (id, gw) => {
        gw = populatedCtor(gw, IChatGateway);
        if (
          !gw ||
          rootGetters['auth/isAdmin'] ||
          oidEquals(gw.willAssignSenderToTeam, rootGetters['auth/myTeam'])
        ) {
          commit('setGateway', { id, gw });
        }
      },
    );
  },
  disconnect({ commit }) {
    socket?.disconnect();
    socket = null;
    commit('reset');
  },
};

const getters = {
  hasWarning: state => Object.keys(state.gateways).length,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
