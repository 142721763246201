export function getOid(v) {
  return typeof v === 'string' ? v : v && '_id' in v ? v._id : v;
}

export function oidEquals(a, b) {
  a = getOid(a);
  b = getOid(b);
  return a === b || (a == null && b == null) || String(a) === String(b);
}

export function openPrintWindow() {
  // Get HTML to print from element
  const prtHtml = document.getElementById('print').innerHTML;

  // Get all stylesheets HTML
  let stylesHtml = '';
  for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const WinPrint = window.open(
    '',
    '',
    'left=0,top=0,width=700,height=900,toolbar=0,scrollbars=0,status=0',
  );

  WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();

  window.onafterprint = function (event) {
    console.log('Event', event);
  };
}

export function emptyPaginationResult() {
  return { docs: [], limit: 10, offset: 0, page: 1, pages: 1, total: 0 };
}
