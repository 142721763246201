import { IClientProfile, IProfile } from '@/api/auth/membership';
import {
  GlobalSchemaFields,
  populatedArrayCtor,
  populatedCtor,
  crudReadCtor,
} from '@/api/mongooseTypes';
import { AxiosInstance } from 'axios';
import { oidEquals } from '@/services/utils';

/**
 * @param {AxiosInstance} api
 * @param {{offset?: number, limit?: number, page?: number, select?: any}} [params]
 * @return {Promise<{docs: IChat[], total: number, page: number, pages: number, offset: number, limit: number}>}
 */
export async function chatList(api, params) {
  const { data } = await api.get('im/chats', { params });
  return {
    ...data,
    docs: populatedArrayCtor(data.docs, IChat),
  };
}

/**
 * @param {AxiosInstance} api
 * @param {string|string[]} id
 * @param {*} [params]
 * @return {Promise<IChat | Record<string, IChat>>}
 */
export async function readChat(api, id, params) {
  const { data } = await api.get('im/chats/' + (Array.isArray(id) ? ',' + id.join(',') : id), {
    params,
  });
  return crudReadCtor(data, IChat);
}

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 */
export async function markRead(api, chatId) {
  await api.get('im/chats/' + chatId + '/read');
}

/**
 * ```
 * {
 *   // sender identity. used to select the gateway instance
 *   // the gw instance is identified by the pair (sender, platform)
 *   "from": "60e2c6fbea5249f40564a073",
 *   // text message content
 *   "body": "@85256603112 is in mentionedPhone, but @85260873181 is not",
 *   // specify either toPhone, toProfile or toChat
 *   //"toPhone": "85260873181",
 *   "toProfile": "60e2966aea5249f405602243",
 *   //"toChat": "60e29678ea5249f40560227b"
 *   // if specify toProfile or toPhone, platform is required.
 *   // no need for toChat since chat is bound to a platform
 *   "platform": "chatapi",
 *
 *   // other gateway specific options
 *   //"quotedMsg": "60e3bd65ea5249f4057b7a09"
 *   "mentionedPhones": ["85256603112"]
 * }
 * ```
 *
 * @param {AxiosInstance} api
 * @param {{
 *  from?: string,
 *  body: string,
 *  platform?: string,
 *  toChat?: string | string[],
 *  toProfile?: string | string[],
 *  toPhone?: string | string[],
 * }} payload
 */
export async function sendMessage(api, payload) {
  return (await api.post('im/chat/sendMessage', payload)).data;
}
/**
 * @param {AxiosInstance} api
 * @param {{
 *  clientTag: false | string[],
 *  body: string,
 *  platform?: string,
 *  from?: string,
 *  preview?: boolean,
 * }} payload
 */
export async function queueMessage(api, payload) {
  return (await api.post('im/chat/queueMessage', payload)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 * @param {string} agent
 */
export async function assignChatToAgent(api, chatId, agent) {
  await api.post(`im/chats/${chatId}/assignTo`, {
    agent,
  });
}

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 * @param {string} agentId
 */
export async function removeAgentFromChat(api, chatId, agentId) {
  await api.post(`im/chats/${chatId}/unassign`, {
    agent: agentId,
  });
}

export class IChat extends GlobalSchemaFields {
  /** @type {string} */
  chatId;
  /** @type {IClientProfile[]} */
  participants = [];
  /** @type {string} */
  platform;
  /** @type {boolean} */
  isGroup;
  metadata;
  /** @type {IClientProfile} */
  senderIdentity;
  /** @type {IProfile[]} */
  _assignedAgents = [];
  /** @type {IClientProfile} */
  get sender() {
    return this.senderIdentity?._id
      ? this.senderIdentity
      : this.participants?.find(x => oidEquals(x, this.senderIdentity));
  }
  /** @type {IClientProfile} */
  get recipient() {
    return this.participants?.find(x => !oidEquals(x, this.senderIdentity));
  }

  constructor(obj) {
    super(obj);
    obj.participants = populatedArrayCtor(obj.participants, IClientProfile) || [];
    obj.senderIdentity = populatedCtor(obj.senderIdentity, IClientProfile);
    obj._assignedAgents = populatedArrayCtor(obj._assignedAgents, IProfile) || [];
    Object.assign(this, obj);
  }
}

export class IChatMessage extends GlobalSchemaFields {
  /** @type {string} */
  body;
  /** @type {IClientProfile} */
  author;
  /** @type {string} */
  ack;

  constructor(obj) {
    super(obj);
    obj.author = populatedCtor(obj.author, IClientProfile);
    Object.assign(this, obj);
  }
}
