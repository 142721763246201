<template>
  <v-expansion-panels>
    <v-expansion-panel v-if="docs[0]">
      <v-expansion-panel-header class="panelHeader">
        <span class="textColor"
          >The chat has been idled for {{ computedTime(docs[0].lastAssignedTime) }} minutes. Review
          the chat now</span
        >
        <div style="text-align: end; white-space: nowrap">
          <v-btn class="approveBtn" color="success" fab @click="review(docs[0], 0)">
            <v-icon> mdi-check </v-icon>
          </v-btn>
          <v-btn class="cancelBtn" color="primary" fab @click="close(0)">
            <v-icon> mdi-window-close </v-icon>
          </v-btn>
        </div>
        <template v-slot:actions>
          <v-icon v-if="needExpand"> $expand </v-icon>
          <v-icon v-else style="visibility: hidden" disabled>$expand</v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="panelHeader" v-if="docs.length > 1">
        <div class="panelContent" v-for="(x, i) in docs.slice(1)" :key="x._id">
          <span class="textColor">
            The chat has been idled for {{ computedTime(x.lastAssignedTime) }} minutes. Review the
            chat now
          </span>
          <div style="text-align: end; white-space: nowrap">
            <v-btn class="approveBtn" color="success" fab @click="review(x, i + 1)">
              <v-icon> mdi-check </v-icon>
            </v-btn>
            <v-btn class="cancelBtn" color="primary" fab @click="close(i + 1)">
              <v-icon> mdi-window-close </v-icon>
            </v-btn>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';

export default {
  name: 'idleChatsAlert.vue',
  props: {
    docs: [],
  },
  data() {
    return {};
  },
  computed: {
    needExpand() {
      return this.docs.length !== 1;
    },
  },
  methods: {
    review(docs, i) {
      const id = docs._id;
      this.$router.push({ name: 'Chat', params: { id } }).catch(() => {});
      this.close(i);
    },
    close(i) {
      this.docs.splice(i, 1);
    },
    computedTime(time) {
      return Math.round(moment(new Date()).diff(moment(time), 'm'));
    },
  },
};
</script>

<style scoped lang="scss">
.panelHeader {
  padding-right: 24px;
  background-color: #769fc3;
  cursor: initial;
}
.panelContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}
.textColor {
  text-align: left;
  color: white;
  font-size: 20px;
}
.approveBtn {
  margin: 0 10px;
}
.cancelBtn {
  margin: 0 10px;
}
</style>
