import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import VeeValidate, { ValidationObserver, ValidationProvider } from 'vee-validate';
import VueClipboard from 'vue-clipboard2';
import * as filters from './services/filters';
import moment from 'moment';
import 'moment-duration-format';
//  dont display chinese
// moment.locale('zh-');
Vue.use(VueClipboard);
Vue.use(VeeValidate);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.mixin({
  methods: {
    async $awaitRef(ref, maxTrial = 50) {
      let x;
      while (!(x = this.$refs[ref]) && maxTrial--) {
        await this.$nextTick();
      }
      return x;
    },
  },
});

Vue.config.productionTip = false;

for (let [k, v] of Object.entries(filters)) {
  if (typeof v === 'function') {
    Vue.filter(k, v);
  }
}

(async () => {
  try {
    await store.dispatch('auth/checkLoginStatus');
  } catch (e) {
    // ignore
  }

  new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
  }).$mount('#app');
})();
