import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {string} content
 * @param {string[] | 'all' | 'managers' | 'agents'} recipients
 */
export async function sendNotificationToAll(api, content, recipients) {
  return (await api.post('im/admin/sendNotificationToAll', { content: content, to: recipients }))
    .data;
}

// /**
//  * @param {AxiosInstance} api
//  * @param {string} content
//  */
// export async function sendNotificationToTeam(api, content) {
//   return api.post('im/admin/sendNotificationToTeam', { content: content });
// }

/**
 * @param {AxiosInstance} api
 * @param {{filter?: *, limit?: number, offset?: number, page?: number, select?: string[], sort?:{}}} [params]
 * @return {Promise<{docs: *[], total: number, offset: number}>}
 */
export async function listNotification(api, params) {
  return (await api.get('users/me/notifications', { params })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string|string[]} ids
 * @return {Promise<*>}
 */
export async function notificationMarkAsRead(api, ids) {
  return (
    await api.get(`users/me/notifications/${ids}/read`, {
      params: {
        ids: ids.join(','),
      },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param data
 */
export async function emailInvitation(api, data) {
  await api.post('im/admin/notify/assignedToTeam', data);
}
