import { IClientProfile } from '@/api/auth/membership';
import { GlobalSchemaFields, populatedArrayCtor, populatedCtor } from '@/api/mongooseTypes';
import { ITeam } from '@/api/team';
import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {*} params
 * @return {Promise<{docs: IChatGateway[], total: number, offset: number, limit: number}>}
 */
export async function listGateways(api, params) {
  const data = (await api.get('im/admin/gws', { params })).data;
  return {
    ...data,
    docs: populatedArrayCtor(data.docs, IChatGateway),
  };
}

/**
 * @param {AxiosInstance} api
 * @param {*} payload
 */
export async function createGateway(api, payload) {
  return populatedCtor((await api.post('im/admin/gws/', payload)).data, IChatGateway);
}

/**
 * @param {AxiosInstance} api
 * @param id
 * @param {*} patch
 */
export async function patchGateway(api, id, patch) {
  return populatedCtor((await api.patch('im/admin/gws/' + id, patch)).data, IChatGateway);
}

/**
 * @param {AxiosInstance} api
 * @param id
 */
export async function deleteGateway(api, id) {
  await api.delete('im/admin/gws/' + id);
}

/**
 * @param {AxiosInstance} api
 * @param id
 */
export async function triggerResync(api, id) {
  await api.post(`im/admin/gws/${id}/setup`);
  await api.post(`im/admin/gws/${id}/syncStatus`);
}

/**
 * @param {AxiosInstance} api
 * @param id
 */
export async function chatApiLogout(api, id) {
  await api.post(`im/gw/chatapi/${id}/logout`);
}

/**
 * @param {AxiosInstance} api
 * @param id
 */
export async function chatApiRefreshQr(api, id) {
  await api.post(`im/gw/chatapi/${id}/expiry`);
}

export class IChatGateway extends GlobalSchemaFields {
  /** @type {string} */
  platform;
  /** @type {IClientProfile} */
  senderIdentity;
  /** @type {ITeam} */
  willAssignSenderToTeam;

  constructor(obj) {
    super(obj);
    const clazz =
      {
        chatapi: IChatapiGateway,
        telegram: ITelegramGateway,
      }[obj.platform] || IChatGateway;
    if (!(this instanceof clazz)) {
      return new clazz(obj);
    }
    obj.senderIdentity = populatedCtor(obj.senderIdentity, IClientProfile);
    obj.willAssignSenderToTeam = populatedCtor(obj.willAssignSenderToTeam, ITeam);
    Object.assign(this, obj);
  }
}

export class IChatapiGateway extends IChatGateway {
  /** @type {'chatapi'} */
  platform = 'chatapi';
  /**
   * @type {{
   *    accountStatus: string,
   *    qrCode?: string,
   *    statusData?: {
   *      substatus: string,
   *      reason?: string,
   *      msg?: string,
   *    }
   *  }}
   */
  status;

  get hasWarning() {
    return (
      this.status?.accountStatus === 'authenticated' &&
      this.status.statusData?.substatus !== 'normal'
    );
  }

  set hasWarning(_) {}

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class ITelegramGateway extends IChatGateway {
  /** @type {'telegram'} */
  platform = 'telegram';
  /**
   * @type {{
   *    is_bot: true,
   *    username: string,
   *  }}
   */
  me;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }
}
