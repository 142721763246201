<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      styleEL: document.createElement('style'),
    };
  },
  created() {
    document.head.appendChild(this.styleEL);
  },
  watch: {
    overrideStyles: {
      immediate: true,
      handler(v) {
        this.styleEL.innerHTML = v;
      },
    },
  },
  computed: {
    overrideStyles() {
      let css = '';
      const themes = this.$vuetify.theme.themes;
      for (const variant in themes) {
        for (const k of Object.keys(themes[variant])) {
          if (themes[variant][k + '_inverse']) {
            css += `
#app .v-application .${k} {
  color: ${themes[variant][k + '_inverse']} !important;
}`;
          }
        }
      }
      return css;
    },
  },
};
</script>

<style lang="scss">
@import '~@/style/index.scss';
</style>
