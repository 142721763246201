import api from '@/api';
import ApiError from '@/api/ApiError';
import { listMyProfiles } from '@/api/auth';
import { ssoCheckLoginStatus, ssoLogout } from '@/api/auth/sso';
import { createSocket } from '@/api/socket';
import { getOid } from '@/services/utils';
import { Socket } from 'socket.io-client';
import { getMyTeam } from '@/api/team';
import moment from 'moment';

const state = {
  allowedRoles: ['admin', 'manager', 'agent'],
  mustLogin: true,
  user: null,
  profile: null,
  profiles: [],
  timezone: null,
  /** @type {Boolean | Error} */
  loginBlocked: false,
};
/** @type {Socket} */
let socket = null;

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  setProfiles(state, profiles) {
    state.profiles = Array.isArray(profiles) ? profiles : [];
  },
  setLoginBlocked(state, blocked) {
    state.loginBlocked = blocked;
  },
  setTimezone(state, timezone) {
    state.timezone = timezone;
  },
};

const actions = {
  async checkLoginStatus({ commit, state, dispatch }) {
    try {
      const data = await ssoCheckLoginStatus(api);
      const team = await getMyTeam(api);
      commit('setUser', data.user);
      commit('setProfile', data.profile);
      if (team) {
        commit('setTimezone', team.workTimePeriod?.timezone);
      } else {
        const i = new Date().getTimezoneOffset();
        commit('setTimezone', -i);
      }
      if (!data.user && state.mustLogin) {
        throw new Error('mustLogin');
      }
      if (data.profile && !state.allowedRoles.includes(data.profile.role)) {
        throw new Error('allowedRoles');
      }
      if (data.user) {
        commit('setProfiles', (await listMyProfiles(api, { limit: 50 })).docs);
      } else {
        commit('setProfiles', []);
      }
      dispatch('initSocket');
      return data;
    } catch (e) {
      socket?.disconnect();
      socket = null;
      throw e;
    }
  },
  async logout({ commit }) {
    try {
      await ssoLogout(api);
    } catch (e) {}
    socket?.disconnect();
    socket = null;
    commit('setUser', null);
    commit('setProfile', null);
    commit('setProfiles', []);
  },
  initSocket({ commit, dispatch, getters }) {
    socket?.disconnect();
    // [check] nsp:/
    socket = createSocket(api, '/');
    socket.on('tokenInvalidated', v => {
      dispatch('logout');
    });
    socket.on('profileCanLogin', v => {
      commit('setLoginBlocked', v === true ? false : ApiError.wrap(v));
    });
    if (getters.isManager) {
      socket.on('tokenNeedApproval', id => {
        dispatch('pendingLoginManagePanel/fetchPendingTokens', null, { root: true });
      });
      // initial fetch once
      dispatch('pendingLoginManagePanel/fetchPendingTokens', null, { root: true });
    }
  },
};

const getters = {
  user: state => state.user,
  profile: state => state.profile,
  profiles: state => state.profiles,
  userId: state => getOid(state.user),
  profileId: state => getOid(state.profile),
  role: state => state.profile?.role,
  isAdmin: (state, getters) => getters.role === 'admin',
  isAgent: (state, getters) => getters.role === 'agent',
  isManager: (state, getters) => getters.role === 'manager',
  myTeam: state => state.profile?.team,
  isLoggedIn: state => !!state.user,
  loginBlocked: state => state.loginBlocked,
  timezone: state => state.timezone,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
