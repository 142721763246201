const state = {
  notify: false,
};

const actions = {
  notify({ commit }, needNotify) {
    commit('setNotification', needNotify);
  },
  readNotify({ commit }) {
    commit('setNotification', false);
  },
};

const mutations = {
  setNotification(state, haveNotification) {
    state.notify = haveNotification;
  },
};

const getters = {
  needNotify: state => state.notify,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
