<template>
  <v-overlay>
    <slot />
  </v-overlay>
</template>

<script>
export default {
  name: 'BlockedView',
};
</script>

<style scoped lang="scss">
.v-overlay {
  top: 64px; //based on nav-bar 64px
}
</style>
